import { Box, Flex } from '@chakra-ui/react';
import { ShareIcon } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { Tooltip } from '../../../../components/Tooltip/Tooltip';

export interface ToolbarClearRowProps {
  clearAllLines: () => void;
  undoLine: () => void;
}

export const ToolbarClearRow: FC<ToolbarClearRowProps> = memo(({ clearAllLines, undoLine }) => {
  return (
    <Flex gap={{ base: '150', md: '200' }}>
      <Tooltip label="Undo">
        <Box as="button" maxW="23px" onClick={undoLine} transform="scaleX(-1)" type="button">
          <ShareIcon />
        </Box>
      </Tooltip>
      <Flex alignItems="center" as="button" onClick={clearAllLines} textStyle="sm" type="button">
        Clear
      </Flex>
    </Flex>
  );
});
