import { Box } from '@chakra-ui/react';
import type { BrandColors } from '@ours/utils';
import { Border } from '@ours/utils';
import type { FC, MutableRefObject, PointerEvent } from 'react';
import { memo } from 'react';
import type * as Y from 'yjs';

import { useColorEnum } from '../../../hooks/useColorEnum';

import { Line } from './Line';
import { PromptContainer } from './PromptContainer';

interface Props {
  bgColor: string;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  handlePointerDown: (e: PointerEvent<any>) => void;
  handlePointerMove: (e: PointerEvent<any>) => void;
  handlePointerUp: (e: PointerEvent<any>) => void;
  lines: Y.Map<any>[];
  prompt: string;
  promptColor: BrandColors;
}

export const DrawingCanvas: FC<Props> = memo(
  ({
    bgColor,
    containerRef,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    lines,
    prompt,
    promptColor,
  }) => {
    const color = useColorEnum(promptColor, 'blue');

    return (
      <Box
        bg={bgColor}
        border={Border}
        borderBottomWidth="0px !important"
        borderTopRightRadius="800"
        h="full"
        pos="relative"
        ref={containerRef}
        w="full"
      >
        <PromptContainer bg={color}>{prompt}</PromptContainer>
        <svg
          baseProfile="full"
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          style={{ height: '100%', touchAction: 'none', width: '100%' }}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g transform={`translate(0, 0)`}>
            {/* Lines */}
            {lines.map((line) => (
              <Line key={line.get('id')} line={line} />
            ))}
          </g>
        </svg>
      </Box>
    );
  }
);
