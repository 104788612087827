export const getCanvasWithViewBox = (canvas: HTMLDivElement) => {
  const svgCanvas = canvas.getElementsByTagName('svg')[0]!;

  const width = canvas.offsetWidth;
  const height = canvas.offsetHeight;

  svgCanvas.setAttribute('viewBox', `0 0 ${width} ${height}`);

  svgCanvas.setAttribute('width', width.toString());
  svgCanvas.setAttribute('height', height.toString());
  return { height, svgCanvas, width };
};
