/* eslint-disable max-lines-per-function */
import type { PointerEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Y from 'yjs';

import { useEvent } from '../../../hooks/useEvent';

import type { PenTheme, YJSLineType } from './getNewYDoc';
import { getNewYDoc } from './getNewYDoc';
import { getPoint } from './getPoint';

/**
 * Subscribe to changes in the document's lines and get functions
 * for creating, update, and modifying the document's lines.
 */
export function useLines(args: { color: string; penTheme: PenTheme }) {
  const [lines, setLines] = useState<Y.Map<YJSLineType>[]>([]);
  const currentLineRef = useRef<Y.Map<any> | null>(null);
  const undoManagerRef = useRef<Y.UndoManager | null>(null);
  const docRef = useRef<Y.Doc | null>(null);
  const yLinesRef = useRef<Y.Array<Y.Map<any>> | null>(null);

  // Observe changes to the yLines shared array; and when
  // changes happen, update the React state with the current
  // value of yLines.
  useEffect(() => {
    const { doc, undoManager, yLines } = getNewYDoc();
    undoManagerRef.current = undoManager;
    docRef.current = doc;
    yLinesRef.current = yLines;

    function handleChange() {
      const lines = yLines.toArray();
      setLines(lines);
    }

    yLines.observe(handleChange);

    return () => {
      yLines.unobserve(handleChange);
      doc.destroy();
    };
  }, []);

  // When the user starts a new line, create a new shared
  // array and add it to the yLines shared array. Store a
  // ref to the new line so that we can update it later.
  const startLine = useEvent((point: [number, number]) => {
    const id = Date.now().toString();
    const yPoints = new Y.Array<number>();
    yPoints.push([...point]);

    const yLine = new Y.Map<any>();

    // Make sure that the next undo starts with the
    // transaction we're about to make.
    undoManagerRef.current?.stopCapturing();
    docRef.current?.transact(() => {
      yLine.set('id', id);
      yLine.set('points', yPoints);
      yLine.set('color', args.color);
      yLine.set('penTheme', args.penTheme);
      yLine.set('isComplete', false);
    });

    currentLineRef.current = yLine;

    yLinesRef.current?.push([yLine]);
  });

  // When the user draws, add the new point to the current
  // line's points array. This will be subscribed to in a
  // different hook.
  const addPointToLine = useEvent((point: [number, number]) => {
    const currentLine = currentLineRef.current;

    if (!currentLine) {
      return;
    }

    const points = currentLine.get('points');

    // Don't add the new point to the line
    if (!points) {
      return;
    }

    points.push([...point]);
  });

  // When the user finishes, update the `isComplete` property
  // of the line.
  const completeLine = useEvent(() => {
    const currentLine = currentLineRef.current;

    if (!currentLine) {
      return;
    }

    currentLine.set('isComplete', true);
    currentLineRef.current = null;
  });

  // Clear all of the lines in the line
  const clearAllLines = useEvent(() => {
    yLinesRef.current?.delete(0, yLinesRef.current.length);
  });

  // Undo the most recently done line
  const undoLine = useEvent(() => {
    undoManagerRef.current?.undo();
  });

  // Redo the most recently undone line
  const redoLine = useEvent(() => {
    undoManagerRef.current?.redo();
  });

  // On pointer down, start a new current line
  const handlePointerDown = useEvent((e: PointerEvent<SVGSVGElement>) => {
    e.currentTarget.setPointerCapture(e.pointerId);
    const bcr = e.currentTarget.getBoundingClientRect();
    startLine(getPoint(e.clientX - bcr.x, e.clientY - bcr.y));
  });

  const handlePointerMove = useEvent((e: PointerEvent<SVGSVGElement>) => {
    const bcr = e.currentTarget.getBoundingClientRect();
    const point = getPoint(e.clientX - bcr.x, e.clientY - bcr.y);
    if (e.currentTarget.hasPointerCapture(e.pointerId)) {
      addPointToLine(point);
    }
  });

  // On pointer up, complete the current line
  const handlePointerUp = useEvent((e: PointerEvent<SVGSVGElement>) => {
    e.currentTarget.releasePointerCapture(e.pointerId);

    completeLine();
  });

  return useMemo(
    () => ({
      addPointToLine,
      clearAllLines,
      completeLine,
      handlePointerDown,
      handlePointerMove,
      handlePointerUp,
      lines,
      redoLine,
      startLine,
      undoLine,
    }),
    [
      handlePointerDown,
      handlePointerMove,
      handlePointerUp,
      addPointToLine,
      clearAllLines,
      completeLine,
      lines,
      redoLine,
      startLine,
      undoLine,
    ]
  );
}
