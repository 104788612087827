import { AspectRatio, Flex } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import type { BrandColors } from '@ours/utils';
import { fileNameWithDate } from '@ours/utils';
import { downloadABlob } from 'better-file-downloader';
import type { FC } from 'react';
import { memo, useRef, useState } from 'react';

import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useAsyncEventMachine } from '../../hooks/useAsyncEventMachine';
import { useBrandedToast } from '../../hooks/useBrandedToast';
import { useEvent } from '../../hooks/useEvent';
import { useEventOnce } from '../../hooks/useEventOnce';
import { canvasToBlob } from '../../lib/image/canvasToBlob';
import { loadImage } from '../../lib/image/loadImage';
import type { PhotoRatios, PhotoSizeKind } from '../../lib/image/photoSizeConstants';
import { PhotoSizeByKind, PhotoSizeToRatio } from '../../lib/image/photoSizeConstants';

import { DrawingCanvas } from './lib/DrawingCanvas';
import { DrawingToolbar } from './lib/DrawingToolbar/DrawingToolbar';
import { getCanvasWithViewBox } from './lib/getCanvasWithViewBox';
import type { PenTheme } from './lib/getNewYDoc';
import { useLines } from './lib/useLines';

export interface DrawingProps {
  bgColor?: string;
  fileName?: string;
  maxW?: string;
  onSave?: (blob: Blob) => Promise<void>;
  prompt: Maybe<string>;
  promptColor?: BrandColors;
  quality?: PhotoSizeKind;
  ratio?: PhotoRatios;
  saveText?: string;
}

export const Drawing: FC<DrawingProps> = memo(
  ({
    bgColor = 'white',
    fileName,
    maxW = '600px',
    onSave,
    prompt,
    promptColor = 'yellow',
    quality = 'print',
    ratio = '6x4',
    saveText = 'Save',
  }) => {
    const onDownloadBlob = useEvent(async (blob: Blob) => {
      downloadABlob(blob, {
        contentType: 'image/png',
        extension: '.png',
        fileName: fileNameWithDate(fileName || 'OUR Drawing'),
      });
    });
    const [drawingColor, setDrawingColor] = useState('#FFA6E3');
    const [customColor, setCustomColor] = useState('#F0EAE5');
    const [penTheme] = useState<PenTheme>('marker');

    const containerRef = useRef<HTMLDivElement | null>(null);
    const toast = useBrandedToast();
    const { trackEvent } = useAnalyticsEvent();

    const onDownloadLocal = useEvent(async () => {
      if (!containerRef.current) {
        return toast({ title: 'Error downloading your drawing :(' });
      }
      trackEvent({ type: 'DRAWING_DOWNLOAD' });
      const { svgCanvas } = getCanvasWithViewBox(containerRef.current);
      const { h, w } = PhotoSizeByKind[quality][ratio];
      const renderCanvas = document.createElement('canvas');
      renderCanvas.width = w;
      renderCanvas.height = h;

      const ctx = renderCanvas.getContext('2d');
      if (!ctx) {
        return toast({ title: 'Error downloading your drawing :(' });
      }

      const image = await loadImage(`data:image/svg+xml;base64,${btoa(svgCanvas.outerHTML)}`);
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, renderCanvas.width, renderCanvas.height);
      ctx.drawImage(image, 0, 0, w, h);
      const fn = onSave || onDownloadBlob;
      await fn((await canvasToBlob(renderCanvas)) as Blob);
    });

    const [onDownload, state] = useAsyncEventMachine(onDownloadLocal);
    const {
      clearAllLines,
      handlePointerDown,
      handlePointerMove,
      handlePointerUp,
      lines,
      undoLine,
    } = useLines({ color: drawingColor, penTheme });

    const onClick = useEventOnce(() => {
      trackEvent({ type: 'DRAWING_START' });
    });

    return (
      <Flex flexDir="column" maxW={maxW} onClick={onClick} w="full">
        <AspectRatio ratio={PhotoSizeToRatio[ratio]}>
          <DrawingCanvas
            bgColor={bgColor}
            containerRef={containerRef}
            handlePointerDown={handlePointerDown}
            handlePointerMove={handlePointerMove}
            handlePointerUp={handlePointerUp}
            lines={lines}
            prompt={prompt || 'Draw a photo!'}
            promptColor={promptColor}
          />
        </AspectRatio>
        <DrawingToolbar
          clearAllLines={clearAllLines}
          customColor={customColor}
          downloadState={state}
          onDownload={onDownload}
          saveText={saveText}
          selectedColor={drawingColor}
          setColor={setDrawingColor}
          setCustomColor={setCustomColor}
          undoLine={undoLine}
        />
      </Flex>
    );
  }
);
