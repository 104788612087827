import { useEffect, useMemo, useState } from 'react';
import type * as Y from 'yjs';

import type { YJSLineType } from './getNewYDoc';
import { toPairs } from './toPairs';

export function useLine(line: Y.Map<any>) {
  const [current, setCurrent] = useState<Pick<YJSLineType, 'penTheme' | 'color' | 'isComplete'>>({
    color: '',
    isComplete: false,
    penTheme: 'marker',
  });
  const [pts, setPts] = useState<number[][]>([]);

  // Subscribe to changes to the line itself and sync
  // them into React state.
  useEffect(() => {
    function handleChange() {
      const current = line.toJSON() as YJSLineType;
      setCurrent({
        color: current.color,
        isComplete: current.isComplete,
        penTheme: current.penTheme,
      });
    }

    handleChange();

    line.observe(handleChange);

    return () => {
      line.unobserve(handleChange);
    };
  }, [line]);

  // Subscribe to changes in the line's points array and sync
  // them into React state.
  useEffect(() => {
    const points = line.get('points') as unknown as Y.Array<any>;

    function handleChange() {
      setPts(toPairs(points.toArray()));
    }

    handleChange();

    points.observe(handleChange);

    return () => {
      points.unobserve(handleChange);
    };
  }, [line]);

  return useMemo(() => ({ ...current, points: pts }), [current, pts]);
}
