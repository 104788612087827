import type { AspectRatioProps } from '@chakra-ui/react';

export type PhotoRatios = '6x4';
export type PhotoSizeKind = 'web' | 'print';
type PhotoSizeLookup = Record<PhotoRatios, { h: number; w: number }>;
export const PhotoSizeConstants: PhotoSizeLookup = {
  '6x4': { h: 2000, w: 3000 },
};
export const PhotoSizeConstantsWeb: PhotoSizeLookup = {
  '6x4': { h: 750, w: 1125 },
};

export const PhotoSizeByKind: Record<PhotoSizeKind, PhotoSizeLookup> = {
  print: PhotoSizeConstants,
  web: PhotoSizeConstantsWeb,
};
export const PhotoSizeToRatio: Record<PhotoRatios, AspectRatioProps['ratio']> = {
  '6x4': 6 / 4,
};
