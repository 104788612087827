import { useMachine } from '@xstate/react';

import type { AsyncEventStatesTypes } from './asyncEventMachine';
import { asyncEventMachine } from './asyncEventMachine';
import { useEvent } from './useEvent';

export const useAsyncEventMachine = <Func extends (...args: any[]) => Promise<any>>(
  fn: Func
): [act: () => void, state: AsyncEventStatesTypes] => {
  const [{ value }, send] = useMachine(asyncEventMachine);
  const state = value as AsyncEventStatesTypes;

  const act = useEvent(async () => {
    send({ fn, type: 'INIT' });
  });

  return [act, state];
};
