import { Box, Flex, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { shouldTextBeBlack } from '@ours/utils';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { HexColorPicker } from 'react-colorful';
import { MdOutlineColorLens } from 'react-icons/md';

import { useEvent } from '../../hooks/useEvent';

const preselectedColors = ['#FFA6E3', '#FFAB00', '#69A1F2', '#AB4A42', '#FF7760', '#000000'];
const colorDim = '32px';

export interface ColorPickerProps {
  customColor: string;
  selectedColor: string;
  setColor: (next: string) => void;
  setCustomColor: (next: string) => void;
}

export const ColorPicker: FC<ColorPickerProps> = memo(
  ({ customColor, selectedColor, setColor, setCustomColor }) => {
    const onCustomColorInit = useEvent(() => {
      setColor(customColor);
    });
    const isCustomColorTextBlack = useMemo(() => shouldTextBeBlack(customColor), [customColor]);

    const onCustomColorChange = useEvent((color: string) => {
      setColor(color);
      setCustomColor(color);
    });

    return (
      <Flex gap={{ base: '50', md: '200' }}>
        {preselectedColors.map((color) => (
          <Box
            as="button"
            bg={color}
            border={selectedColor === color ? '2px solid white' : undefined}
            boxShadow={selectedColor === color ? '#000 0px 0px 0px 1px' : undefined}
            cursor="pointer"
            h={colorDim}
            key={color}
            onClick={() => setColor(color)}
            rounded="full"
            type="button"
            w={colorDim}
          />
        ))}

        <Popover>
          <PopoverTrigger>
            <Box
              alignItems="center"
              as="button"
              bg={customColor}
              border={selectedColor === customColor ? '2px solid white' : undefined}
              boxShadow={selectedColor === customColor ? '#000 0px 0px 0px 1px' : undefined}
              cursor="pointer"
              display="flex"
              h={colorDim}
              justifyContent="center"
              onClick={onCustomColorInit}
              rounded="full"
              type="button"
              w={colorDim}
            >
              <MdOutlineColorLens color={isCustomColorTextBlack ? 'black' : 'white'} />
            </Box>
          </PopoverTrigger>
          <PopoverContent bg="transparent" border="none" width="unset">
            <HexColorPicker color={customColor} onChange={onCustomColorChange} />
          </PopoverContent>
        </Popover>
      </Flex>
    );
  }
);
