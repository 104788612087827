import { Box } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface PromptContainerProps {
  bg: string;
  children: ReactNode;
}

export const PromptContainer: FC<PromptContainerProps> = memo(({ bg, children }) => {
  return (
    <Box
      data-testid="PromptContainer"
      display="flex"
      justifyContent="center"
      left="-1px"
      pointerEvents="none"
      pos="absolute"
      top={{ base: '-1px', md: '-2px' }}
      w="full"
      zIndex={20}
    >
      <Box
        bg={bg}
        border={Border}
        borderBottomLeftRadius={{ base: '0', sm: '700' }}
        borderBottomRightRadius={{ base: '0', sm: '700' }}
        px={{ base: '200', md: '300' }}
        py={{ base: '50', md: '100' }}
        textAlign="center"
        w={{ base: 'full', sm: 'auto' }}
      >
        {children}
      </Box>
    </Box>
  );
});
