import type { FC } from 'react';
import { memo } from 'react';

import { ColorPicker } from '../../../ColorPicker/ColorPicker';

export interface ToolbarColorRowProps {
  customColor: string;
  selectedColor: string;
  setColor: (next: string) => void;
  setCustomColor: (next: string) => void;
}

export const ToolbarColorRow: FC<ToolbarColorRowProps> = memo(
  ({ customColor, selectedColor, setColor, setCustomColor }) => {
    return (
      <ColorPicker
        customColor={customColor}
        selectedColor={selectedColor}
        setColor={setColor}
        setCustomColor={setCustomColor}
      />
    );
  }
);
