export function toPairs<T>(arr: T[]): T[][] {
  const pairs: T[][] = [];

  for (let i = 0; i < arr.length - 1; i += 2) {
    // @ts-expect-error idk
    pairs.push([arr[i], arr[i + 1]]);
  }

  return pairs;
}
