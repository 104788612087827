import { getStroke } from 'perfect-freehand';
import { memo } from 'react';
import type * as Y from 'yjs';

import { getSvgPathFromStroke } from './getSvgPathFromStroke';
import { useLine } from './useLine';

export interface LineProps {
  line: Y.Map<any>;
}

export const Line = memo(({ line }: LineProps) => {
  const { color, isComplete, points } = useLine(line);
  const pathData = getSvgPathFromStroke(
    getStroke(points, {
      last: isComplete,
      size: 12,
      smoothing: 0.7,
      streamline: 0.6,
      thinning: 0.5,
    })
  );

  return (
    <g fill={color}>
      <path className="canvas-line" d={pathData} fill={color} />
    </g>
  );
});
