import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../../components/Buttons/Button';
import type { AsyncEventStatesTypes } from '../../../../hooks/asyncEventMachine';

export interface ToolbarExportRowProps {
  downloadState: AsyncEventStatesTypes;
  onDownload: () => void;
  saveText: string;
}

export const ToolbarExportRow: FC<ToolbarExportRowProps> = memo(
  ({ downloadState, onDownload, saveText }) => {
    return (
      <Flex data-testid="ExportRow" pb="1px">
        <Button
          as="button"
          isLoading={downloadState === 'acting'}
          onClick={onDownload}
          size="sm"
          testId="Drawing.ExportBtn"
          type="button"
        >
          {saveText}
        </Button>
      </Flex>
    );
  }
);
