import { Flex } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import type { AsyncEventStatesTypes } from '../../../../hooks/asyncEventMachine';

import { ToolbarClearRow } from './ToolbarClearRow';
import { ToolbarColorRow } from './ToolbarColorRow';
import { ToolbarExportRow } from './ToolbarExportRow';

export interface DrawingToolbarProps {
  clearAllLines: () => void;
  customColor: string;
  downloadState: AsyncEventStatesTypes;
  onDownload: () => void;
  saveText: string;
  selectedColor: string;
  setColor: (next: string) => void;
  setCustomColor: (next: string) => void;
  undoLine: () => void;
}

export const DrawingToolbar: FC<DrawingToolbarProps> = memo(
  ({
    clearAllLines,
    customColor,
    downloadState,
    onDownload,
    saveText,
    selectedColor,
    setColor,
    setCustomColor,
    undoLine,
  }) => {
    return (
      <>
        <Flex
          alignItems="center"
          bg="white"
          border={Border}
          borderBottomLeftRadius="800"
          display={{ base: 'none', md: 'flex' }}
          justifyContent="space-between"
          pl="400"
          pr="200"
          py="200"
          w="full"
        >
          <ToolbarColorRow
            customColor={customColor}
            selectedColor={selectedColor}
            setColor={setColor}
            setCustomColor={setCustomColor}
          />
          <ToolbarClearRow clearAllLines={clearAllLines} undoLine={undoLine} />
          <ToolbarExportRow
            downloadState={downloadState}
            onDownload={onDownload}
            saveText={saveText}
          />
        </Flex>
        <Flex
          bg="white"
          border={Border}
          borderBottomLeftRadius="800"
          display={{ base: 'flex', md: 'none' }}
          flexDir="column"
          gap="200"
          px="100"
          py="100"
          w="full"
        >
          <Flex gap="200" justifyContent="center" overflowX="scroll" py="1px" w="full">
            <ToolbarColorRow
              customColor={customColor}
              selectedColor={selectedColor}
              setColor={setColor}
              setCustomColor={setCustomColor}
            />
          </Flex>
          <Flex gap="400" justifyContent="center" w="full">
            <ToolbarClearRow clearAllLines={clearAllLines} undoLine={undoLine} />
            <ToolbarExportRow
              downloadState={downloadState}
              onDownload={onDownload}
              saveText={saveText}
            />
          </Flex>
        </Flex>
      </>
    );
  }
);
