import { useMemo, useRef } from 'react';

import { useEvent } from './useEvent';

export const useEventOnce = (fn: (...args: any[]) => void) => {
  const hasFired = useRef(false);
  const act = useEvent(fn);

  return useMemo(() => {
    return () => {
      if (hasFired.current) {
        return;
      }
      hasFired.current = true;
      return act();
    };
  }, [act]);
};
