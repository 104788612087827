import type { ToXStateSchema } from '@ours/types';
import { assertType, noop } from '@ours/utils';
import type { MachineConfig } from 'xstate';
import { assign, createMachine } from 'xstate';

interface Context {
  fn: () => Promise<void>;
}

const baseContext: Context = {
  fn: noop,
};

type InitAction = {
  fn: () => Promise<void>;
  type: 'INIT';
};
type Actions = InitAction;

export const States = ['acting', 'success', 'failure', 'init'] as const;
export type AsyncEventStatesTypes = (typeof States)[number];

const machineSchema: MachineConfig<Context, ToXStateSchema<typeof States>, Actions> = {
  context: baseContext,
  description: '',
  id: 'asyncEventMachine',
  initial: 'init',
  predictableActionArguments: true,
  states: {
    acting: {
      invoke: {
        onDone: { target: 'success' },
        onError: { target: 'failure' },
        src: async (ctx) => {
          return await ctx.fn();
        },
      },
    },
    failure: { after: { 2000: { target: 'init' } } },
    init: { on: { INIT: { actions: 'init', target: 'acting' } } },
    success: { after: { 2000: { target: 'init' } } },
  },
};

export const asyncEventMachine = createMachine(machineSchema, {
  actions: {
    init: assign((_, event) => {
      assertType(event, 'INIT');
      return { fn: event.fn };
    }),
  },
});
