// import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';

export type PenTheme = 'marker' | 'watercolor';
export interface YJSLineType {
  color: string;
  isComplete: boolean;
  penTheme: PenTheme;
  points: [number, number];
}
export const getNewYDoc = () => {
  const VERSION = Date.now().toString();
  const doc = new Y.Doc();

  // Create a websocket provider (but don't connect just yet)
  // export const provider = new WebsocketProvider('wss://demos.yjs.dev', `draw-demo-${VERSION}`, doc, {
  //   connect: false,
  // });

  // // Export the provider's awareness API
  // export const awareness = provider.awareness;

  // Get a shared array of our line maps
  const yLines: Y.Array<Y.Map<any>> = doc.getArray(`lines-${VERSION}`);

  // Create an undo manager for the line maps
  const undoManager = new Y.UndoManager(yLines);

  return {
    doc,
    undoManager,
    yLines,
  };
};
