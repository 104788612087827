import type { FC } from 'react';
import { memo } from 'react';

export const ShareIcon: FC = memo(() => {
  return (
    <svg fill="none" height="29" viewBox="0 0 35 29" width="35" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2081 16.774C7.80751 17.0212 3.33384 23.2942 2.02093 27.5185C1.92327 27.8327 1.4167 27.8268 1.35921 27.5028C-1.00469 14.1805 8.71887 7.37574 18.1986 6.92838C18.7503 6.90234 19.1983 6.45704 19.1983 5.90476V2.10181C19.1983 1.25963 20.1756 0.794613 20.8291 1.32589L33.0296 11.2451C33.527 11.6495 33.5208 12.4108 33.0169 12.8071L20.8164 22.401C20.1603 22.9169 19.1983 22.4496 19.1983 21.615V17.7561C19.1983 17.2088 18.7553 16.761 18.2081 16.774Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});
